.image {
  border: 10px solid;
  border-color: #ffffff;
  height: 346px;
  position: relative;
  width: 618px;
}

.image .img {
  height: 326px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 598px;
}
