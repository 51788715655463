.screen-5 {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.screen-5 .iphone-plus-wrapper {
  height: 749px;
  width: 414px;
}

.screen-5 .iphone-plus-4 {
  background-color: #ffffff;
  height: 749px;
}

.screen-5 .overlap-22 {
  background-color: #00000080;
  height: 746px;
  position: relative;
  width: 414px;
}

.screen-5 .rectangle-10 {
  background-color: #ffffff;
  height: 746px;
  left: 120px;
  position: absolute;
  top: 0;
  width: 294px;
}

.screen-5 .text-wrapper-69 {
  color: #000000;
  font-family: "Fredoka One", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 385px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
}

.screen-5 .menu-2 {
  height: 346px;
  left: 149px;
  position: absolute;
  top: 80px;
  width: 164px;
}

.screen-5 .overlap-group-15 {
  height: 59px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.screen-5 .text-wrapper-70 {
  color: #3abda5;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 57px;
}

.screen-5 .rectangle-11 {
  background-color: #3abda5;
  height: 10px;
  left: 0;
  position: absolute;
  top: 29px;
  width: 60px;
}

.screen-5 .text-wrapper-71 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 102px;
  width: 156px;
}

.screen-5 .text-wrapper-72 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 195px;
  width: 97px;
}

.screen-5 .text-wrapper-73 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 287px;
  width: 73px;
}
