.screen-9 {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.screen-9 .iphone-plus-7 {
  background-color: #ffffff;
  height: 749px;
  width: 414px;
}

.screen-9 .overlap-36 {
  background-color: #00000080;
  height: 746px;
  position: relative;
}

.screen-9 .rectangle-18 {
  background-color: #ffffff;
  height: 746px;
  left: 120px;
  position: absolute;
  top: 0;
  width: 294px;
}

.screen-9 .text-wrapper-105 {
  color: #000000;
  font-family: "Fredoka One", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 385px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
}

.screen-9 .menu-4 {
  height: 346px;
  left: 149px;
  position: absolute;
  top: 80px;
  width: 164px;
}

.screen-9 .text-wrapper-106 {
  color: #383935;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 57px;
}

.screen-9 .text-wrapper-107 {
  color: #383935;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 86px;
  width: 156px;
}

.screen-9 .overlap-group-20 {
  height: 59px;
  left: 0;
  position: absolute;
  top: 178px;
  width: 156px;
}

.screen-9 .text-wrapper-108 {
  color: #3abda5;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 97px;
}

.screen-9 .rectangle-19 {
  background-color: #3abda5;
  height: 10px;
  left: 0;
  position: absolute;
  top: 33px;
  width: 156px;
}

.screen-9 .text-wrapper-109 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 287px;
  width: 73px;
}
