.screen-7 {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.screen-7 .desktop-3 {
  background-color: #ffffff;
  height: 5666px;
  position: relative;
  width: 1440px;
}

.screen-7 .logo-3 {
  display: block;
  height: 57px;
  left: 212px;
  object-fit: cover;
  position: absolute;
  top: 29px;
  width: 57px;
}

.screen-7 .navbar {
  height: 35px;
  left: 782px;
  position: absolute;
  top: 29px;
  width: 536px;
}

.screen-7 .text-wrapper-79 {
  color: #383935;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.screen-7 .text-wrapper-80 {
  color: #3abda5;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 110px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.screen-7 .text-wrapper-81 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 312px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.screen-7 .text-wrapper-82 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 455px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.screen-7 .rectangle-14 {
  background-color: #3abda5;
  height: 5px;
  left: 110px;
  position: absolute;
  top: 30px;
  width: 156px;
}

.screen-7 .overlap-24 {
  background-color: #fefaf1;
  height: 275px;
  left: 0;
  position: absolute;
  top: 125px;
  width: 1440px;
}

.screen-7 .button-7 {
  height: 60px;
  left: 591px;
  position: absolute;
  top: 173px;
  width: 242px;
}

.screen-7 .overlap-group-17 {
  background-color: #3abda5;
  border-radius: 12px;
  height: 60px;
  position: relative;
  width: 240px;
}

.screen-7 .text-wrapper-83 {
  color: #ffffff;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 85px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 17px;
}

.screen-7 .text-wrapper-84 {
  color: #062e5d;
  font-family: "Francois One", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 647px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 57px;
}

.screen-7 .text-wrapper-85 {
  color: #062e5d;
  font-family: "Archivo Black", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 430px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 92px;
  width: 581px;
}

.screen-7 .group-79 {
  height: 1072px;
  left: 157px;
  position: absolute;
  top: 461px;
  width: 1129px;
}

.screen-7 .text-wrapper-86 {
  color: #383935;
  font-family: "Francois One", Helvetica;
  font-size: 36px;
  font-weight: 400;
  left: 470px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.screen-7 .group-80 {
  height: 921px;
  left: 572px;
  position: absolute;
  top: 149px;
  width: 555px;
}

.screen-7 .overlap-group-18 {
  height: 921px;
  position: relative;
  width: 553px;
}

.screen-7 .rectangle-15 {
  height: 824px;
  left: 0;
  position: absolute;
  top: 0;
  width: 553px;
}

.screen-7 .image-40 {
  height: 246px;
  left: 209px;
  object-fit: cover;
  position: absolute;
  top: 562px;
  width: 246px;
}

.screen-7 .image-41 {
  height: 343px;
  left: 54px;
  object-fit: cover;
  position: absolute;
  top: 578px;
  width: 357px;
}

.screen-7 .text-wrapper-87 {
  color: #383935;
  font-family: "Arimo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 61px;
  width: 497px;
}

.screen-7 .group-81 {
  height: 913px;
  left: 0;
  position: absolute;
  top: 159px;
  width: 555px;
}

.screen-7 .overlap-25 {
  height: 913px;
  position: relative;
  width: 553px;
}

.screen-7 .text-wrapper-88 {
  color: #000000;
  font-family: "Arimo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 56px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 62px;
  width: 497px;
}

.screen-7 .image-42 {
  height: 135px;
  left: 85px;
  object-fit: cover;
  position: absolute;
  top: 595px;
  width: 148px;
}

.screen-7 .image-43 {
  height: 327px;
  left: 85px;
  object-fit: cover;
  position: absolute;
  top: 586px;
  width: 317px;
}

.screen-7 .text-wrapper-89 {
  color: #8e8a8a;
  font-family: "Arimo", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 293px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: 594px;
}

.screen-7 .group-82 {
  height: 171px;
  left: 251px;
  position: absolute;
  top: 1631px;
  width: 990px;
}

.screen-7 .text-wrapper-90 {
  color: #383935;
  font-family: "Francois One", Helvetica;
  font-size: 36px;
  font-weight: 400;
  left: 347px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.screen-7 .text-wrapper-91 {
  color: #383935;
  font-family: "Arimo", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 199px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: 594px;
}

.screen-7 .group-83 {
  height: 38px;
  left: 0;
  position: absolute;
  top: 133px;
  width: 986px;
}

.screen-7 .group-84 {
  height: 33px;
  left: 497px;
  position: absolute;
  top: 0;
  width: 491px;
}

.screen-7 .img-wrapper {
  background-color: #adeeec;
  border-radius: 16.5px;
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 33px;
}

.screen-7 .image-44 {
  height: 22px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 24px;
}

.screen-7 .element-7 {
  color: #000000;
  font-family: "Arimo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 45px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 5px;
  white-space: nowrap;
  width: 444px;
}

.screen-7 .text-wrapper-92 {
  font-weight: 700;
}

.screen-7 .text-wrapper-93 {
  color: #000000;
  font-family: "Arimo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
}

.screen-7 .group-85 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 470px;
}

.screen-7 .overlap-26 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 37px;
}

.screen-7 .ellipse-9 {
  background-color: #86bc0033;
  border-radius: 16.5px;
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 33px;
}

.screen-7 .image-45 {
  height: 22px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 34px;
}

.screen-7 .element-8 {
  color: #000000;
  font-family: "Arimo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 54px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 5px;
  width: 414px;
}

.screen-7 .text-wrapper-94 {
  color: #000000;
  font-family: "Arimo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
}

.screen-7 .group-86 {
  height: 689px;
  left: 104px;
  position: absolute;
  top: 1964px;
  width: 1210px;
}

.screen-7 .overlap-27 {
  background-image: url(../../../static/img/rectangle-10-3.svg);
  background-size: 100% 100%;
  height: 591px;
  left: 0;
  position: absolute;
  top: 98px;
  width: 382px;
}

.screen-7 .rectangle-16 {
  height: 501px;
  left: 10px;
  position: absolute;
  top: 80px;
  width: 362px;
}

.screen-7 .thirukkural-research-3 {
  color: #383935;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 104px;
  width: 345px;
}

.screen-7 .text-wrapper-95 {
  color: #ffffff;
  font-family: "Francois One", Helvetica;
  font-size: 44px;
  font-weight: 400;
  left: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 27px;
}

.screen-7 .overlap-28 {
  background-image: url(../../../static/img/rectangle-28.svg);
  background-size: 100% 100%;
  height: 590px;
  left: 407px;
  position: absolute;
  top: 99px;
  width: 382px;
}

.screen-7 .rectangle-17 {
  height: 501px;
  left: 9px;
  position: absolute;
  top: 79px;
  width: 362px;
}

.screen-7 .onboarding-teachers-2 {
  color: #383935;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 15px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 103px;
  width: 352px;
}

.screen-7 .text-wrapper-96 {
  color: #ffffff;
  font-family: "Francois One", Helvetica;
  font-size: 44px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 26px;
}

.screen-7 .overlap-29 {
  background-image: url(../../../static/img/rectangle-29-1.svg);
  background-size: 100% 100%;
  height: 590px;
  left: 814px;
  position: absolute;
  top: 99px;
  width: 382px;
}

.screen-7 .thirukkural-research-4 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 103px;
  width: 330px;
}

.screen-7 .image-46 {
  height: 120px;
  left: 125px;
  object-fit: cover;
  position: absolute;
  top: 385px;
  width: 120px;
}

.screen-7 .text-wrapper-97 {
  color: #ffffff;
  font-family: "Francois One", Helvetica;
  font-size: 44px;
  font-weight: 400;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 26px;
}

.screen-7 .text-wrapper-98 {
  color: #383935;
  font-family: "Francois One", Helvetica;
  font-size: 36px;
  font-weight: 400;
  left: 517px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.screen-7 .component-1-instance {
  left: 0 !important;
  position: absolute !important;
  top: 5503px !important;
}

.screen-7 .overlap-30 {
  background-image: url(../../../static/img/rectangle-15-3.svg);
  background-size: 100% 100%;
  height: 352px;
  left: 27px;
  position: absolute;
  top: 5086px;
  width: 1387px;
}

.screen-7 .overlap-31 {
  height: 290px;
  left: 191px;
  position: absolute;
  top: 31px;
  width: 270px;
}

.screen-7 .ellipse-10 {
  background-color: #fed24c;
  border-radius: 80.5px/78.5px;
  height: 157px;
  left: 40px;
  position: absolute;
  top: 16px;
  width: 161px;
}

.screen-7 .image-47 {
  height: 143px;
  left: 14px;
  mix-blend-mode: lighten;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 194px;
}

.screen-7 .image-48 {
  height: 290px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 270px;
}

.screen-7 .text-wrapper-99 {
  color: #ffffff;
  font-family: "Archivo Black", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 518px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
  width: 581px;
}

.screen-7 .button-8 {
  height: 60px;
  left: 694px;
  position: absolute;
  top: 204px;
  width: 242px;
}

.screen-7 .overlap-32 {
  background-color: #fed24c;
  border-radius: 12px;
  height: 60px;
  position: relative;
  width: 240px;
}

.screen-7 .text-wrapper-100 {
  color: #383935;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 85px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 17px;
}
