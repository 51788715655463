.screen-11 {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.screen-11 .desktop-4 {
  background-color: #ffffff;
  height: 1622px;
  position: relative;
  width: 1440px;
}

.screen-11 .overlap-40 {
  background-image: url(../../../static/img/banner-2.png);
  background-size: 100% 100%;
  height: 488px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 1439px;
}

.screen-11 .navbar-2 {
  height: 35px;
  left: 781px;
  position: absolute;
  top: 29px;
  width: 537px;
}

.screen-11 .text-wrapper-119 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.screen-11 .text-wrapper-120 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 110px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.screen-11 .text-wrapper-121 {
  color: #000000;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 312px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.screen-11 .text-wrapper-122 {
  color: #3abda5;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 455px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.screen-11 .rectangle-21 {
  background-color: #3abda5;
  height: 5px;
  left: 455px;
  position: absolute;
  top: 30px;
  width: 74px;
}

.screen-11 .text-wrapper-123 {
  color: #1d7ba4;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 317px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 270px;
}

.screen-11 .text-wrapper-124 {
  color: #383935;
  font-family: "Francois One", Helvetica;
  font-size: 36px;
  font-weight: 400;
  left: 243px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 204px;
}

.screen-11 .component-2 {
  left: 0 !important;
  position: absolute !important;
  top: 1459px !important;
}

.screen-11 .overlap-41 {
  background-image: url(../../../static/img/rectangle-15-3.svg);
  background-size: 100% 100%;
  height: 352px;
  left: 27px;
  position: absolute;
  top: 754px;
  width: 1387px;
}

.screen-11 .overlap-42 {
  height: 290px;
  left: 191px;
  position: absolute;
  top: 31px;
  width: 270px;
}

.screen-11 .ellipse-15 {
  background-color: #fed24c;
  border-radius: 80.5px/78.5px;
  height: 157px;
  left: 40px;
  position: absolute;
  top: 16px;
  width: 161px;
}

.screen-11 .image-56 {
  height: 143px;
  left: 14px;
  mix-blend-mode: lighten;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 194px;
}

.screen-11 .image-57 {
  height: 290px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 270px;
}

.screen-11 .text-wrapper-125 {
  color: #ffffff;
  font-family: "Archivo Black", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 656px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 60px;
  width: 581px;
}

.screen-11 .button-10 {
  height: 60px;
  left: 656px;
  position: absolute;
  top: 185px;
  width: 242px;
}

.screen-11 .overlap-43 {
  background-color: #fed24c;
  border-radius: 12px;
  height: 60px;
  position: relative;
  width: 240px;
}

.screen-11 .text-wrapper-126 {
  color: #383935;
  font-family: "Hannari-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 78px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 19px;
}

.screen-11 .text-wrapper-127 {
  color: #ffffff;
  font-family: "Francois One", Helvetica;
  font-size: 36px;
  font-weight: 400;
  left: 656px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 98px;
}
