@media only screen and (max-width: 500px){
  .desktopHome {
    display: none;
  }
}

@media only screen and (min-width: 500px){
  .mobileHome {
    display: none;
  }
}

